import React from 'react';
import Menu1 from '../../../assets/jpg/menu1.jpeg';
import Menu2 from '../../../assets/jpg/menu2.jpeg';
import Menu3 from '../../../assets/jpg/menu3.jpeg';
import "./Menu.scss";

const Menu = () => {
  return (
    <div className='menu_doggy'>
      <img src={Menu1} alt="" />
      <img src={Menu2} alt="" />
      <img src={Menu3} alt="" />
    </div>
  )
}

export default Menu
